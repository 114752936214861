import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { CloseIcon } from '../../../assets/svg';
import { MESSAGE } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonButton from '../button/Button';

const { required, whitespace } = formValidatorRules;

export default function EditProfileModal({
  id,
  name,
  isOpen = false,
  handleOk = () => {},
  handleCancel = () => {},
  handleFinish = () => {},
  ...rest
}) {
  const [form] = useForm();

  const onFinish = (values) => {
    handleFinish(values);
    handleCancel();
  };

  return (
    <Modal
      title={
        <div className="edit-profile-header">
          <p>Edit Profile</p>
          <CloseIcon onClick={handleCancel} />
        </div>
      }
      className="edit-profile-modal"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      {...rest}
    >
      <Form
        name="edit-profile"
        initialValues={{ fullName: name }}
        onFinish={onFinish}
        size="large"
        form={form}
        layout="vertical"
        className="edit-profile-form"
      >
        <Form.Item
          name="fullName"
          label="Full Name"
          className="mb-16"
          rules={[{ required, message: MESSAGE.required }, whitespace]}
        >
          <Input placeholder="Enter your full name" />
        </Form.Item>

        <Form.Item shouldUpdate className="full-width mt-24">
          {() => (
            <CommonButton
              type="primary"
              block
              htmlType="submit"
              shape="round"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form?.getFieldsError()?.filter(({ errors }) => errors?.length)
                  ?.length
              }
            >
              Save
            </CommonButton>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
