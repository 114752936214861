import { delay } from 'lodash';
import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import { ACTION_TYPES, REFRESH_TOKEN, TOKEN } from './common/constants';

const initialState = {
  animation: {
    mode: true,
  },
  chats: [],
  search: '',
  user: {},
  currentUser: null,
  // eslint-disable-next-line no-undef
  authToken: localStorage?.getItem(TOKEN),
};

const reducer = (state, action) => {
  switch (action?.type) {
    case ACTION_TYPES.SET_CURRENT_USER:
      return { ...state, user: action.data };
    case ACTION_TYPES.SET_TOKEN:
      // eslint-disable-next-line no-undef
      localStorage.setItem(TOKEN, action?.data);
      return { ...state, authToken: action.data };
    case ACTION_TYPES.SET_REFRESH_TOKEN:
      // eslint-disable-next-line no-undef
      localStorage.setItem(REFRESH_TOKEN, action?.data);
      return { ...state, refreshToken: action.data };
    case ACTION_TYPES.UPDATE_ANIMATION:
      return {
        ...state,
        animation: {
          ...state?.animation,
          ...action?.data,
        },
      };
    case ACTION_TYPES.SET_AUTHENTICATED:
      return { ...state, authenticated: action?.data };
    case ACTION_TYPES.LOGOUT:
      delete api?.defaults?.headers?.common?.Authorization;
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      client?.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
      };
    case ACTION_TYPES.UPDATE_SEARCH_QUERY:
      return {
        ...state,
        search: action?.data,
      };
    case ACTION_TYPES.TRIGGER_SCROLL:
      delay(() => {
        // eslint-disable-next-line no-undef
        const element = document?.getElementById(action?.data);
        if (element) {
          // element.scrollTop = action?.data;
          element?.scrollIntoView();
        }
      }, 100);
      return state;
    case ACTION_TYPES.UPDATE_CHATS:
      return {
        ...state,
        chats: action?.data,
      };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(TOKEN) || null;
  const getRefreshToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(REFRESH_TOKEN);

  const isAuthenticated = () => state?.authenticated;

  const initializeAuth = (authToken, user, refreshToken) => {
    const token = authToken || getToken();
    const refresh = refreshToken || getRefreshToken();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: ACTION_TYPES.SET_TOKEN, data: token });
      dispatch({ type: ACTION_TYPES.SET_REFRESH_TOKEN, data: refresh });
      dispatch({ type: ACTION_TYPES.SET_AUTHENTICATED, data: true });
      dispatch({ type: ACTION_TYPES.SET_CURRENT_USER, data: user });
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    getRefreshToken,
    initializeAuth,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
