import { Skeleton } from 'antd';
import React from 'react';

const SlickCardSkeleton = () => (
  <div className="asset-card">
    <Skeleton.Image active />
    <div className="asset-meta">
      <Skeleton className="asset-title" active />
    </div>
  </div>
);

export default SlickCardSkeleton;
