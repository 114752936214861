import { useMutation } from '@apollo/client';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import { EmailVerification } from './graphql/mutations';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('uid');
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const [verifyEmail, { loading, error, data }] = useMutation(
    EmailVerification,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    if (uid && token) {
      verifyEmail({
        variables: {
          data: {
            uid,
            token,
          },
        },
      });
    }
  }, [uid, token, verifyEmail]);

  useEffect(() => {
    if (error) {
      navigate(ROUTES.LOGIN);
    }
    if (data) {
      if (data?.emailVerification?.data?.alreadySignUp) {
        navigate(ROUTES.LOGIN);
      } else {
        navigate(ROUTES.SIGNUP, {
          state: {
            email: atob(uid),
          },
        });
      }
    }
  }, [error, data]);

  return <Spin spinning={loading} />;
};

export default VerifyEmail;
