import React from 'react';
import OtpForm from './OtpForm';

const VerifyOtp = () => (
  <section className="container">
    <div className="login-wrapper">
      <OtpForm />
    </div>
  </section>
);

export default VerifyOtp;
