import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import { AppRouterHolder, navigateTo } from './components/AppRouterHolder';
import MaintenancePage from './components/common/MaintenancePage';
import AuthWrapper from './modules/auth/AuthWrapper';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import RefreshToken from './modules/auth/RefreshToken';
import SignUp from './modules/auth/SignUp';
import VerifyEmail from './modules/auth/VerifyEmail';
import VerifyOtp from './modules/auth/VerifyOtp';
import { GET_CURRENT_USER } from './modules/auth/graphql/queries';
import Home from './modules/home';

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES?.LOGIN,

      element: <PublicRoute />,
      children: [
        {
          path: ROUTES?.LOGIN,
          element: (
            <AuthWrapper>
              <Login />
            </AuthWrapper>
          ),
        },
      ],
    },
    {
      path: ROUTES?.SIGNUP,

      element: <PublicRoute />,
      children: [
        {
          path: ROUTES?.SIGNUP,
          element: (
            <AuthWrapper>
              <SignUp />
            </AuthWrapper>
          ),
        },
      ],
    },
    {
      path: ROUTES?.VERIFY_OTP,

      element: <PublicRoute />,
      children: [
        {
          path: ROUTES?.VERIFY_OTP,
          element: (
            <AuthWrapper>
              <VerifyOtp />
            </AuthWrapper>
          ),
        },
      ],
    },
    {
      path: ROUTES?.VERIFY_EMAIL,

      element: <PublicRoute />,
      children: [
        {
          path: ROUTES?.VERIFY_EMAIL,
          element: (
            <AuthWrapper>
              <VerifyEmail />
            </AuthWrapper>
          ),
        },
      ],
    },
    // commenting for future use
    // {
    //   path: ROUTES?.FORGET_PASSWORD,
    //   element: <PublicRoute />,
    //   children: [
    //     { path: ROUTES?.FORGET_PASSWORD, element: <ForgetPassword /> },
    //   ],
    // },
    // {
    //   path: ROUTES?.SIGNUP,
    //   element: <PublicRoute />,
    //   children: [{ path: ROUTES?.SIGNUP, element: <Signup /> }],
    // },
    // {
    //   path: ROUTES?.RESET,
    //   element: <PublicRoute />,
    //   children: [{ path: ROUTES?.RESET, element: <ResetPassword /> }],
    // },
    {
      path: ROUTES?.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.LOGOUT, element: <Logout /> }],
    },
    {
      path: ROUTES?.AUTHENTICATION,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.AUTHENTICATION, element: <RefreshToken /> }],
    },
  ];

  const HOME_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [{ path: ROUTES?.MAIN, element: <Home /> }],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: '*',
      element: <Error404 />,
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULES,
    ...HOME_MODULES,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const { initializeAuth, getToken, state } = useContext(AppContext);
  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;
  const idToken = getToken();
  // eslint-disable-next-line no-undef
  const path = window?.location?.pathname;
  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(idToken, res?.user);
    },
    onError: () => {
      navigateTo(ROUTES?.AUTHENTICATION);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const ACCESS_TOKEN = localStorage?.getItem('TOKEN');
    if (
      ACCESS_TOKEN &&
      !state?.currentUser &&
      path !== ROUTES?.AUTHENTICATION
    ) {
      getCurrentUser();
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
        <AppRouterHolder />
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
