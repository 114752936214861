import { Button } from 'antd';
import React from 'react';

export default function CommonButton({ children, ...rest }) {
  return (
    <Button className="common-button" {...rest}>
      {children}
    </Button>
  );
}
