import React from 'react';
import { Link } from 'react-router-dom';
import Header from './app/components/header/Header';
import { ROUTES } from './common/constants';
import CommonButton from './components/common/button/Button';

const Error404 = () => (
  <div className="page-flex bg-app">
    <Header />
    <div className="wrapper not-found-wrapper">
      <div className="container">
        <div className="not-found-content">
          <h1 className="not-found-title medium">Page Not Found</h1>
          <p className="not-found-description">
            The page you are looking for doesn't exist or has been moved
          </p>
          <Link to={ROUTES.MAIN}>
            <CommonButton type="primary" className="btn-home">
              Go to Home
            </CommonButton>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default Error404;
