const themeConfig = {
  token: {
    colorPrimary: 'rgb(223 60 38)',
    colorPrimaryHover: 'var(--color-primary-light)',
    radiusBase: '4px',
    controlRadius: '4px',
    colorLink: 'var(--color-primary)',
    fontFamily: 'unset',
    controlItemBgActive: 'var(--bg-light-black)',
    controlItemBgHover: 'var(--bg-light-black)',
    controlItemBgActiveHover: 'var(--bg-light-black)',
    colorText: 'var(--color-neturals0)',
  },
  components: {
    Button: {
      colorPrimary: 'var(--color-primary)',
      primaryShadow: 'unset',
      colorText: 'var(--color-neturals10)',
      defaultColor: 'var(--color-primary)',
      defaultBorderColor: 'var(--color-primary)',
      controlHeightLG: 48,
    },
    Switch: {
      colorPrimary: 'var(--color-primary)',
      colorPrimaryHover: 'var(--color-primary)',
    },
    Input: {
      activeShadow: 'unset',
      colorBgContainer: 'var(--color-neturals8)',
      controlHeightLG: 48,
      inputFontSize: 22,
    },
    Form: { labelHeight: 24, labelColor: 'var(--color-neturals3)' },
    Skeleton: {
      controlHeight: 24,
    },
    Slider: {
      railBg: 'rgb(255, 255, 255, 0.5)',
      trackBgDisabled: 'var(--color-neturals10)',
      railSize: 8,
    },
    Spin: {
      colorPrimary: 'var(--color-primary)',
    },
  },
};

export default themeConfig;
