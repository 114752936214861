import { useMutation } from '@apollo/client';
import { Form, Input } from 'antd';
import { isNaN } from 'lodash';
import { React, useContext, useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { MESSAGE } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonButton from '../../components/common/button/Button';
import { RESEND_OTP, VERIFY_OTP_INPUT } from './graphql/mutations';

const { required } = formValidatorRules;

export default function OtpForm() {
  const { state } = useLocation();
  // eslint-disable-next-line no-undef
  let storedMinutes = parseInt(localStorage.getItem('storedTimeMinutes'), 10);
  // eslint-disable-next-line no-undef
  let storedSeconds = parseInt(localStorage.getItem('storedTimeSeconds'), 10);
  const [minutes, setMinutes] = useState(storedMinutes || 10);
  const [seconds, setSeconds] = useState(storedSeconds || 0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [form] = Form.useForm();
  const { initializeAuth } = useContext(AppContext);
  const [otpError, setOtpError] = useState(false);
  let interval;
  const [otp, setOtp] = useState('');
  const [verifyOtp, { loading: verifyOtpLoading }] = useMutation(
    VERIFY_OTP_INPUT,
    {
      onError() {},
    },
  );
  const [resendOtp] = useMutation(RESEND_OTP, {
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    // eslint-disable-next-line no-undef
    localStorage.removeItem('storedTimeMinutes');
    // eslint-disable-next-line no-undef
    localStorage.removeItem('storedTimeSeconds');
    // navigate(ROUTES?.MAIN);
  }

  const onFinish = async (values) => {
    verifyOtp({
      variables: {
        data: {
          email: state?.email,
          otp: values?.otp,
        },
      },
      onCompleted(response) {
        if (response?.verifyOtp) {
          const {
            accessToken,
            refreshToken,
            data: userData,
          } = response?.verifyOtp;
          if (successCallback) {
            successCallback(accessToken, userData, refreshToken);
          }
        } else {
          form?.setFieldsValue(values);
        }
      },
      onError() {
        setOtpError(true);
      },
    });
  };
  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const storedMinutesAndSeconds = () => {
    storedMinutes = 10;
    storedSeconds = 0;
    // eslint-disable-next-line no-undef
    localStorage.setItem('storedTimeMinutes', storedMinutes.toString());
    // eslint-disable-next-line no-undef
    localStorage.setItem('storedTimeSeconds', storedSeconds.toString());
  };

  const timeInterVal = () => {
    interval = setInterval(() => {
      if (storedMinutes === 0 && storedSeconds === 0) {
        clearInterval(interval);
        setIsButtonDisabled(false);
      } else if (storedSeconds === 0) {
        // eslint-disable-next-line no-plusplus
        storedMinutes--;
        storedSeconds = 59;
      } else {
        // eslint-disable-next-line no-plusplus
        storedSeconds--;
      }

      setMinutes(storedMinutes);
      setSeconds(storedSeconds);

      // eslint-disable-next-line no-undef
      localStorage.setItem('storedTimeMinutes', storedMinutes.toString());
      // eslint-disable-next-line no-undef
      localStorage.setItem('storedTimeSeconds', storedSeconds.toString());
    }, 1000);
  };

  const handleResendOtp = () => {
    resendOtp({
      variables: {
        where: {
          email: state?.email,
        },
      },
      onCompleted() {
        setIsButtonDisabled(true);
        storedMinutesAndSeconds();
        timeInterVal();
      },
      onError() {},
    });
  };

  const handleOtp = (text) => {
    setOtp(text);
  };

  useEffect(() => {
    if (isNaN(storedMinutes) || isNaN(storedSeconds)) {
      storedMinutesAndSeconds();
    }
    timeInterVal();
    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);
  return (
    <div className="form-wrapper">
      <div className="text-left mb-8">
        <h4 className="text-verify">Verify your identity</h4>
        <p className="body-base medium text-neturals4 mb-24">
          {`Enter the code we’ve sent to ${state?.email}`}
        </p>
      </div>
      <Form
        name="otp"
        onFinish={onFinish}
        size="large"
        form={form}
        layout="vertical"
        className="login-form"
        validateTrigger="onChange"
        onValuesChange={() => {
          setOtpError(false);
        }}
      >
        <div className="form-fields-wrapper">
          <Form.Item
            name="otp"
            validateStatus={otpError ? 'error' : ''}
            rules={[{ required, message: MESSAGE.required }]}
            className="otp-form-field mb-8"
          >
            <OTPInput
              value={otp}
              onChange={(e) => handleOtp(e)}
              numInputs={5}
              inputType="tel"
              placeholder="00000"
              renderInput={(props) => <Input {...props} />}
            />
          </Form.Item>
          <div className="otp-countdown">
            <p className="text-resend">
              {isButtonDisabled ? (
                `Resend OTP in ${minutes}:${padZero(seconds)}` // Use padZero function for formatting
              ) : (
                <span
                  className="text-secondary pointer"
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </span>
              )}
            </p>
          </div>
        </div>
        <Form.Item className=" full-width mt-48 mb-0">
          <CommonButton
            type="primary"
            block
            htmlType="submit"
            shape="round"
            // disabled={!OtpFieldTouched}
            loading={verifyOtpLoading}
            disabled={
              // eslint-disable-next-line no-console
              form.getFieldValue('otp')?.length !== 5 || otpError
            }
          >
            Confirm OTP
          </CommonButton>
        </Form.Item>
      </Form>
    </div>
  );
}
