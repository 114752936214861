import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';

export default function App() {
  const {
    state: { animation },
  } = useContext(AppContext);

  return (
    <div className={`page-flex ${animation?.mode ? 'bg-app' : ''}`}>
      <Header />
      <div
        className={`wrapper ${!animation?.mode ? 'active' : ''}`}
        id="scroll-area"
      >
        <Outlet />
      </div>
      {animation?.mode && <Footer />}
      {!animation?.mode && <div className="absolute-bottom" />}
    </div>
  );
}
