import { gql } from '@apollo/client';

export const LOGOUT_USER = gql`
  mutation logout {
    logout {
      message
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query User {
    user {
      id
      email
      firstName
      lastName
      fullName
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query GetProfileImageUploadSignedUrl(
    $data: GetProfileImageUploadSignedUrlInput!
  ) {
    getProfileImageUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
