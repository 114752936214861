import React from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-mutable-exports
let navigateTo;
export const AppRouterHolder = () => {
  navigateTo = useNavigate();
  return <></>;
};

export { navigateTo };
