import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { ASSET_TYPE } from '../../../common/constants';

export default function Player({ link, type, startTime = 0 }) {
  const [loading, setLoading] = useState(true);
  const [refState, setRefState] = useState(null);

  useEffect(() => {
    if (refState) {
      if (startTime && type === ASSET_TYPE.PODCAST) {
        // seek to start time for PODCAST
        refState?.seekTo(startTime);
      }
    }
  }, [refState]);

  return (
    <div
      className={`player-content ${
        type === ASSET_TYPE.PODCAST || type === ASSET_TYPE.AUDIO ? 'audio' : ''
      }`}
    >
      <Spin spinning={loading}>
        <ReactPlayer
          url={link}
          className="player-container"
          controls
          muted
          playing
          width="100%"
          onReady={(readyRef) => {
            setRefState(readyRef);
            setLoading(false);
          }}
        />
      </Spin>
    </div>
  );
}
