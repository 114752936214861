import { Button, Col, Modal, Row } from 'antd';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import {
  ArticleIcon,
  CloseIcon,
  PodcastIcon,
  RedirectIcon,
  VideoIcon,
  VideoPauseIcon,
} from '../../../assets/svg';
import { ASSET_TYPE, validAssetTypes } from '../../../common/constants';
import { messageContext } from '../../../components/AppContextHolder';
import Player from '../../../components/common/player/Player';

const KeywordBasedResult = ({ data = [] }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const defaultMinHeight = 136;
  const defaultMinWidth = '100%';

  const Controls = ({ assetType, controlsIcon, icon }) => (
    <>
      <p className="floating-tag">
        {icon}
        {capitalize(assetType)}
      </p>
      <div className="floating-video-pause-icon">{controlsIcon}</div>
    </>
  );

  const renderAssets = (assetType) => {
    switch (assetType?.toUpperCase()) {
      case ASSET_TYPE.ARTICLE:
        return (
          <p className="floating-tag">
            <ArticleIcon />
            {assetType}
          </p>
        );
      case ASSET_TYPE.PDF:
        return (
          <p className="floating-tag">
            <ArticleIcon />
            {assetType}
          </p>
        );
      case ASSET_TYPE.VIDEO:
        return (
          <Controls
            assetType={assetType}
            icon={<VideoIcon />}
            controlsIcon={<VideoPauseIcon />}
          />
        );
      case ASSET_TYPE.PODCAST:
        return (
          <Controls
            assetType={assetType}
            icon={<PodcastIcon />}
            controlsIcon={<VideoPauseIcon />}
          />
        );
      case ASSET_TYPE.AUDIO:
        return (
          <Controls
            assetType={assetType}
            icon={<PodcastIcon />}
            controlsIcon={<VideoPauseIcon />}
          />
        );
      case ASSET_TYPE.WEBSITE:
        return (
          <p className="floating-tag">
            <ArticleIcon />
            {assetType}
          </p>
        );

      default:
        return null;
    }
  };

  const handleCancel = () => {
    setIsOpenModal(false);
  };

  const handleCardClick = (event, obj = null) => {
    if (obj?.link) {
      const assetType = obj?.content_type?.toUpperCase();
      if (validAssetTypes?.includes(assetType)) {
        setCurrentData({
          ...obj,
          link: `${obj?.link}&t=${obj?.meta?.startTime || 0}s`,
        });
        setIsModal(true);
        event?.preventDefault();
      } else {
        return false;
      }
    } else {
      messageContext?.error('No URL found.');
    }
  };

  const handleRedirection = (link) => {
    setIsModal(false);
    // eslint-disable-next-line no-undef
    window.open(link, '_blank');
  };

  return data?.length > 0 ? (
    <div className="keyword-container player-model">
      <p className="body-xl mb-16 pl-12">Keyword based results:</p>
      <Row gutter={[24, 24]} className="keyword-row-container">
        {data?.slice(0, 8).map((item) => (
          <Col
            key={item?.source_id}
            className="gutter-row card-wrapper"
            sm={8}
            lg={6}
          >
            <a
              href={item?.link}
              onClick={(event) => handleCardClick(event, item)}
              target="_blank"
              rel="noreferrer"
            >
              <div className="image-wrapper">
                <div className="image-container">
                  <img
                    src={item?.headerImageUrl}
                    alt="card-wrapper-img"
                    height={defaultMinHeight}
                    width={defaultMinWidth}
                  />
                  {renderAssets(item?.content_type)}
                </div>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html:
                      item?.highlighted_title?.length > 0
                        ? item?.highlighted_title
                        : item?.title,
                  }}
                  className="wrapper-title body-s"
                />
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html:
                      item?.highlighted_content?.length > 0
                        ? item?.highlighted_content
                        : item?.content,
                  }}
                  className="description body-base"
                />
              </div>
            </a>
          </Col>
        ))}
      </Row>
      {data?.length >= 8 && (
        <Button
          block
          className="keyword-view-all"
          onClick={() => setIsOpenModal(true)}
        >
          View All
        </Button>
      )}

      <Modal
        title={
          <div className="player-modal-header">
            <p className="body-xl">Keyword based results</p>
            <div className="icon-wrapper">
              <CloseIcon onClick={handleCancel} />
            </div>
          </div>
        }
        open={isOpenModal}
        className="keyword-search-modal player-modal"
        onCancel={handleCancel}
        width={
          currentData?.type?.toUpperCase() === ASSET_TYPE.PODCAST ? 550 : 1087
        }
        centered
        footer={null}
        closeIcon={null}
        destroyOnClose
      >
        <Row gutter={[24, 24]} className="keyword-row-container">
          {data?.map((item) => (
            <Col
              key={item?.source_id}
              className="gutter-row card-wrapper"
              sm={8}
              lg={6}
            >
              <a
                href={item?.link}
                onClick={(event) => handleCardClick(event, item)}
                target="_blank"
                rel="noreferrer"
              >
                <div className="image-wrapper">
                  <div className="image-container">
                    <img
                      src={item?.headerImageUrl}
                      alt="card-wrapper-img"
                      height={defaultMinHeight}
                      width={defaultMinWidth}
                    />
                    {renderAssets(item?.content_type)}
                  </div>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html:
                        item?.highlighted_title?.length > 0
                          ? item?.highlighted_title
                          : item?.title,
                    }}
                    className="wrapper-title body-s"
                  />
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html:
                        item?.highlighted_content?.length > 0
                          ? item?.highlighted_content
                          : item?.content,
                    }}
                    className="description body-base"
                  />
                </div>
              </a>
            </Col>
          ))}
        </Row>
      </Modal>
      <Modal
        title={
          <div className="player-modal-header">
            <p>{currentData?.title}</p>
            <div className="icon-wrapper">
              <RedirectIcon
                onClick={() => handleRedirection(currentData?.link)}
              />
              <CloseIcon onClick={() => setIsModal(false)} />
            </div>
          </div>
        }
        open={isModal}
        className="player-modal"
        onCancel={() => setIsModal(false)}
        width={
          currentData?.content_type?.toUpperCase() === ASSET_TYPE.AUDIO ||
          currentData?.content_type?.toUpperCase() === ASSET_TYPE.PODCAST
            ? 550
            : 1087
        }
        centered
        footer={null}
        closeIcon={null}
        destroyOnClose
      >
        <div className="react-player-wrapper">
          {currentData?.link && (
            <Player
              link={currentData?.link}
              type={currentData?.content_type?.toUpperCase()}
              startTime={currentData?.meta?.startTime}
            />
          )}
        </div>
      </Modal>
    </div>
  ) : (
    <div className="chat-left result-error" id="chat-left">
      <p className="text-result-error">
        The keyword search results do not provide any direct information about
        the above query.
      </p>
    </div>
  );
};
export default KeywordBasedResult;
