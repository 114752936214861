import { ApolloLink, createHttpLink, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { isObject } from 'lodash';
import { ROUTES, TOKEN } from './common/constants';
import { messageContext } from './components/AppContextHolder';
import { navigateTo } from './components/AppRouterHolder';

let disableToastTimeout = null;
export const cacheData = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
});

const toast = ({ message: content, type }) => {
  messageContext?.destroy();
  switch (type) {
    case 'info':
      messageContext?.info(content);
      break;
    case 'success':
      messageContext?.success(content);
      break;
    case 'warning':
      messageContext?.warning(content);
      break;
    case 'error':
      messageContext?.error(content);
      break;
    default:
      break;
  }
};

const authLink = setContext((ctx, { headers }) => {
  // eslint-disable-next-line no-undef
  const userToken = localStorage.getItem(TOKEN);
  let newHeaders = headers || {};

  newHeaders = {
    ...newHeaders,
    Authorization: userToken ? `Bearer ${userToken}` : '',
  };

  return {
    headers: newHeaders,
  };
});

const responseMessageLink = new ApolloLink((operation, forward) =>
  forward(operation)?.map((response) => {
    const { data } = response;

    if (
      data &&
      isObject(data) &&
      Object?.keys(data)?.length > 0 &&
      data?.[`${Object?.keys(data)?.[0]}`]?.message
    ) {
      if (Object?.keys(data)?.[0] === 'forgotUserPassword') {
        if (data?.[`${Object?.keys(data)?.[0]}`]?.status !== 'ERROR') {
          setTimeout(() => {
            toast({
              message:
                data?.[`${Object?.keys(data)?.[0]}`]?.message ||
                'Operation successful',
              type: 'success',
            });
          }, 1000);
        }
      } else {
        setTimeout(() => {
          const oResponse = data?.[`${Object?.keys(data)?.[0]}`];

          if (!response) {
            return;
          }

          toast({
            message: oResponse?.message || 'Operation successful',
            type: oResponse?.status === 'ERROR' ? 'error' : 'success',
          });
        }, 1000);
      }
    }
    return response;
  }),
);

const errorLink = onError((options) => {
  const { graphQLErrors, networkError, response } = options;

  if (networkError?.statusCode === 405) {
    if (disableToastTimeout) {
      clearTimeout(disableToastTimeout);
    }

    disableToastTimeout = setTimeout(() => {
      if (networkError?.result?.message) {
        toast({
          message: networkError?.result?.message,
          type: 'error',
        });
      }
    }, 200);
    navigateTo(ROUTES?.LOGOUT, { replace: true });
    return;
  }

  if (graphQLErrors?.length > 0) {
    const isTokenExpired =
      graphQLErrors?.[0]?.extensions?.code === 'SESSION_EXPIRED' ||
      graphQLErrors?.[0]?.extensions?.code === 'INVALID_TOKEN';

    if (
      !isTokenExpired &&
      graphQLErrors?.[0]?.extensions?.code !==
        'EMAIL_VERIFICATION_TOKEN_EXPIRED'
    ) {
      setTimeout(() => {
        toast({
          message: graphQLErrors?.[0]?.message || 'Something went wrong!',
          type: 'error',
        });
      }, 1000);
    } else {
      navigateTo(ROUTES?.AUTHENTICATION);
    }
  }

  if (response) {
    response?.errors?.map((error) => {
      const { message: errorMessage, locations, path, extensions } = error;

      if (extensions?.code === 'SESSION_EXPIRED') {
        navigateTo(ROUTES?.AUTHENTICATION, {
          // eslint-disable-next-line no-undef
          state: { from: window?.location?.pathname },
        });
      }

      if (extensions?.code === 'EMAIL_VERIFICATION_TOKEN_EXPIRED') {
        navigateTo(ROUTES?.LOGIN, {
          state: {
            emailVerificationError: true,
          },
        });
      }

      if (
        extensions?.code === 'UNAUTHORIZED' ||
        extensions?.code === 405 ||
        extensions?.code === 'INVALID_TOKEN' ||
        extensions?.exception?.name === 'JsonWebTokenError'
      ) {
        navigateTo(ROUTES?.LOGOUT, { replace: true });
      }
      // eslint-disable-next-line no-console
      return console?.log(
        `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console?.log(`[Network error]: ${networkError}`);
    // Enable when sentry is integrated
    // Sentry?.captureException(new Error(`[Network error]: ${networkError}`));
  }
});

const client = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, httpLink]),
});

export default client;
