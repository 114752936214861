import { LoadingOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { delay, map } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import { AIPoweredIcon, ChevronColoredUp, ChevronUp } from '../../assets/svg';
import api from '../../common/api';
import {
  ACTION_TYPES,
  API_ENDPOINTS,
  ASSET_TYPE,
  REGEX,
  validAssetTypes,
} from '../../common/constants';
import { removeLastWordFromArray } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import GET_ASSETS from './graphql/queries';

export default function Search() {
  const {
    dispatch,
    state: { animation, search, chats },
  } = useContext(AppContext);

  const [form] = useForm();
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [fetchAssetsMeta] = useLazyQuery(GET_ASSETS, { onError: () => {} });

  const fetchAIContents = async () => {
    dispatch({
      type: ACTION_TYPES.UPDATE_ANIMATION,
      data: {
        mode: false,
      },
    });

    // Enable textarea loader
    setLoading(true);

    // Add default loader to enable skeleton UI
    let chatData = [
      ...chats,
      {
        query: search,
        loading: true,
        error: false,
        keywordLoading: true,
        keywordError: false,
      },
    ];

    // Update chat data
    dispatch({
      type: ACTION_TYPES.UPDATE_CHATS,
      data: chatData,
    });

    // Enable chat animation
    dispatch({
      type: ACTION_TYPES.TRIGGER_SCROLL,
      data: `chat-${chats?.length}`,
    });

    try {
      // keyword based search api
      const keywordSearch = await api(API_ENDPOINTS.getKeywordAIData, {
        method: 'POST',
        data: {
          question: search?.trim(),
        },
      });

      if (keywordSearch?.data) {
        const updatedData = chatData?.map((item) => {
          if (item?.keywordLoading) {
            return {
              ...item,
              keywordAssetLoading: true,
              keywordAssetError: false,
              keywordLoading: false,
              keywordSearchedData: keywordSearch?.data,
            };
          }
          return item;
        });

        chatData = updatedData;
        const sourceIdListKeyword = map(keywordSearch?.data, 'source_id');
        const lastItemIndex = updatedData.length - 1;
        await fetchAssetsMeta({
          variables: {
            where: { ids: sourceIdListKeyword },
          },
          onCompleted: (res) => {
            const keywordAssetsList = res?.processedAssets;
            if (keywordAssetsList?.length > 0) {
              updatedData[lastItemIndex].keywordAssetLoading = false;
              updatedData[lastItemIndex].keywordAssetError = false;
              map(
                updatedData?.[lastItemIndex]?.keywordSearchedData,
                (value, idx) => {
                  const data = keywordAssetsList?.[idx];
                  // eslint-disable-next-line no-param-reassign
                  value.headerImageUrl = data?.headerImageUrl;
                  // eslint-disable-next-line no-param-reassign
                  value.title = data?.name;
                  // eslint-disable-next-line no-param-reassign
                  value.link = data?.url;
                },
              );
            } else {
              updatedData[lastItemIndex].keywordAssetLoading = false;
              updatedData[lastItemIndex].keywordAssetError = true;

              dispatch({
                type: ACTION_TYPES.UPDATE_CHATS,
                data: updatedData,
              });
            }
            chatData = updatedData;
            dispatch({
              type: ACTION_TYPES.UPDATE_CHATS,
              data: updatedData,
            });
          },
        });

        dispatch({
          type: ACTION_TYPES.UPDATE_CHATS,
          data: updatedData,
        });
      }

      const response = await api(API_ENDPOINTS.getAIData, {
        method: 'POST',
        data: { question: search?.trim() },
      });

      if (response?.data) {
        const updatedData = chatData?.map((item) => {
          if (item?.loading) {
            return {
              ...item,
              loading: false,
              assetLoading: true,
              assetError: false,
              keywordSearchedData: keywordSearch?.data,
              ...response?.data,
            };
          }

          return item;
        });
        // Replace vectors id to blank until we get data from the API response
        const lastItemIndex = updatedData.length - 1;
        let originalText = response?.data?.resText;
        const replacedText = response?.data?.resText?.replace(
          REGEX.VECTOR_ID,
          '',
        );
        updatedData[lastItemIndex].resText = replacedText;
        updatedData[lastItemIndex].resTextPlain = replacedText;
        dispatch({
          type: ACTION_TYPES.UPDATE_CHATS,
          data: updatedData,
        });
        dispatch({
          type: ACTION_TYPES.TRIGGER_SCROLL,
          data: `chat-left-${updatedData?.length - 1}`,
        });
        if (response?.data?.refIds?.length > 0) {
          updatedData[lastItemIndex].assetLoading = true;
          updatedData[lastItemIndex].assetError = false;

          dispatch({
            type: ACTION_TYPES.UPDATE_CHATS,
            data: updatedData,
          });
          // Retrieve source ids to fetch meta data.
          const sourceIdList = map(response?.data?.refIds, 'sourceId');

          // Todo improve if condition for
          if (sourceIdList?.length > 0) {
            await fetchAssetsMeta({
              variables: {
                where: { ids: sourceIdList },
              },
              onCompleted: (res) => {
                const assetsList = res?.processedAssets;
                if (assetsList?.length > 0) {
                  let strCounter = 1;
                  map(updatedData?.[lastItemIndex]?.refIds, (value, idx) => {
                    const data = assetsList?.[idx];
                    // eslint-disable-next-line no-param-reassign
                    value.headerImageUrl = data?.headerImageUrl;
                    // eslint-disable-next-line no-param-reassign
                    value.title = data?.name;
                    if (value?.type?.toUpperCase() === ASSET_TYPE.ARTICLE) {
                      if (value?.meta?.highlightText) {
                        const text = value?.meta?.highlightText?.split(' ');
                        // eslint-disable-next-line no-param-reassign
                        value.link = `${data?.url}#:~:text=${encodeURI(
                          removeLastWordFromArray(text),
                        )}`;
                      } else {
                        // eslint-disable-next-line no-param-reassign
                        value.link = data?.url;
                      }
                    } else {
                      // eslint-disable-next-line no-param-reassign
                      value.link = data?.url;
                    }

                    updatedData[lastItemIndex].resText = originalText;
                    const vectorList = originalText?.match(REGEX.VECTOR_STRING);

                    if (vectorList?.includes(value?.id)) {
                      let anchorLink = value?.link;
                      if (
                        validAssetTypes?.includes(value?.type?.toUpperCase())
                      ) {
                        anchorLink = `${value?.link}&t=${
                          value?.meta?.startTime || 0
                        }s`;
                      }
                      originalText = originalText?.replaceAll(
                        value?.id,
                        `<a href="${anchorLink}" class="text-link" target="_blank">${strCounter}</a>`,
                      );
                      // Update title with vectorId
                      // eslint-disable-next-line no-param-reassign
                      value.title = value?.title
                        ? `${strCounter}. ${value?.title}`
                        : value?.title;
                      strCounter += 1;
                    }
                    return value;
                  });

                  updatedData[lastItemIndex].resText = originalText;
                  updatedData[lastItemIndex].assetLoading = false;
                  updatedData[lastItemIndex].assetError = false;

                  dispatch({
                    type: ACTION_TYPES.UPDATE_CHATS,
                    data: updatedData,
                  });
                } else {
                  // In case of empty asset ids, enable error to hide carousel
                  updatedData[lastItemIndex].assetLoading = false;
                  updatedData[lastItemIndex].assetError = true;

                  dispatch({
                    type: ACTION_TYPES.UPDATE_CHATS,
                    data: updatedData,
                  });
                }
              },
              onError: () => {
                updatedData[lastItemIndex].assetLoading = false;
                updatedData[lastItemIndex].assetError = true;
                dispatch({
                  type: ACTION_TYPES.UPDATE_CHATS,
                  data: updatedData,
                });
              },
            });
          }
        } else {
          updatedData[lastItemIndex].assetLoading = false;
          updatedData[lastItemIndex].assetError = false;

          dispatch({
            type: ACTION_TYPES.UPDATE_CHATS,
            data: updatedData,
          });
        }
      }
    } catch (e) {
      const lastItemIndex = chatData?.length - 1;
      chatData[lastItemIndex].loading = false;
      chatData[lastItemIndex].error = true;
      chatData[lastItemIndex].assetLoading = false;
      chatData[lastItemIndex].assetError = true;
      chatData[lastItemIndex].keywordAssetLoading = false;
      chatData[lastItemIndex].keywordAssetError = true;
      chatData[lastItemIndex].keywordError = true;
      chatData[lastItemIndex].keywordLoading = false;

      dispatch({
        type: ACTION_TYPES.UPDATE_CHATS,
        data: chatData,
      });
      setLoading(false);
    } finally {
      setLoading(false);
      // Clear input query
      dispatch({
        type: ACTION_TYPES.UPDATE_SEARCH_QUERY,
        data: null,
      });
      delay(() => {
        inputRef.current.focus({
          cursor: 'start',
        });
      });
    }
  };

  const handleSearch = () => {
    if (search?.trim().length > 0) {
      fetchAIContents();
    } else {
      messageContext.error('Invalid input!');
    }
  };

  const isDisabled =
    chats?.[chats?.length - 1]?.loading ||
    chats?.[chats?.length - 1]?.assetLoading;

  const renderControls = () => {
    if (search) {
      if (loading) {
        return (
          <LoadingOutlined
            spin
            className="icon-right pointer-none text-neturals6"
          />
        );
      }
      return (
        <ChevronColoredUp
          className="icon-right pointer"
          onClick={() => form?.submit()}
        />
      );
    }
    return <ChevronUp className="icon-right" />;
  };

  return (
    <div className="home-screen">
      <h1 className={`h1 section ${animation?.mode ? 'active' : ''}`}>
        AI powered search:
        <span className="text-highlight"> Just ask a question</span>
      </h1>
      <div className={`input-container ${!animation?.mode ? 'active' : ''}`}>
        <Form
          onFinish={handleSearch}
          form={form}
          onChange={(e) => {
            dispatch({
              type: ACTION_TYPES.UPDATE_SEARCH_QUERY,
              data: e?.target?.value,
            });
          }}
        >
          <Form.Item name="search">
            <div>
              <AIPoweredIcon className="icon-left" />
              <TextArea
                ref={inputRef}
                placeholder="Ask a question"
                className={`${isFocused ? 'active' : ''}`}
                autoFocus
                autoComplete="off"
                disabled={isDisabled}
                value={search}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                }}
                onPressEnter={(e) => {
                  if (e?.keyCode === 13 && !e?.shiftKey) {
                    e?.preventDefault();
                    handleSearch(e);
                  }
                }}
                autoSize={{
                  minRows: 1,
                  maxRows: 2,
                }}
                size="small"
              />
              {renderControls()}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
