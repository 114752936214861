import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_USER = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      data {
        id
        email
        firstName
        lastName
        fullName
      }
      message
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query User {
    user {
      id
      email
      firstName
      lastName
      fullName
      profileImage
      roles
      isActive
      lastSessionTime
      status
    }
  }
`;
