import React from 'react';
import { CappitalLogo } from '../../../assets/svg';
import { COMPANY_INFO } from '../../../common/constants';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer-wrapper">
          <p className="text-copyright">{COMPANY_INFO.FOOTER_TEXT}</p>
          <div className="logicwind-logo">
            <span className="text-company">Created by a Convene member</span>
            <CappitalLogo />
          </div>
        </div>
      </div>
    </footer>
  );
}
