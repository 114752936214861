import React from 'react';
import Chat from './Chat';
import Search from './Search';

const Home = () => (
  <section className="home-wrapper">
    <div className="container">
      <Search />
      <Chat />
    </div>
  </section>
);

export default Home;
