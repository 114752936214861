import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ACTION_TYPES, ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/common/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import { LOGOUT_USER } from './graphql/queries';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const { navigate } = useRouter();
  const [logout, { loading }] = useMutation(LOGOUT_USER, {
    onError() {},
  });

  useEffect(() => {
    logout({
      onCompleted: () => {
        dispatch({ type: ACTION_TYPES.LOGOUT });
        navigate(ROUTES?.LOGIN);
      },
      onError: () => {
        dispatch({ type: ACTION_TYPES.LOGOUT });
        navigate(ROUTES?.LOGIN);
      },
    });
  }, []);

  if (loading) return <LoaderComponent />;
  return null;
};

export default Logout;
