import { gql } from '@apollo/client';

const GET_ASSETS = gql`
  query getProcessedAsset($where: ProcessedAssetsWhereInput!) {
    processedAssets(where: $where) {
      name
      id
      url
      headerImageUrl
    }
  }
`;

export default GET_ASSETS;
