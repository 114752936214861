import React from 'react';
import { DownArrow, PlayIcon } from '../../assets/svg';

export default function CustomTooltipTitle({ leftPosition, time }) {
  return (
    <div className="custom-tooltip" style={{ left: `${leftPosition}%` }}>
      <div className="position-relative">
        <div className="d-flex align-center gap-6">
          <PlayIcon />
          <span className="body-meta">{time}</span>
          <DownArrow className="floating-icon" />
        </div>
      </div>
    </div>
  );
}
