import { useLazyQuery, useMutation } from '@apollo/client';
import { Divider, Dropdown, Flex } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { Logo, ProfileEditIcon, ProfileIcon } from '../../../assets/svg';
import { ACTION_TYPES, ROUTES } from '../../../common/constants';
import EditProfileModal from '../../../components/common/modal/EditProfileModal';
import history from '../../../historyData';
import useRouter from '../../../hooks/useRouter';
import { GET_CURRENT_USER, UPDATE_USER } from './graphql/mutation';

export default function Header() {
  const {
    state: { user },
    dispatch,
  } = useContext(AppContext);
  const { initializeAuth, getToken } = useContext(AppContext);
  const idToken = getToken();
  const { navigate } = useRouter();
  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(idToken, res?.user);
    },
    onError: () => {
      history?.push(ROUTES?.AUTHENTICATION);
    },
  });

  const [updateUser] = useMutation(UPDATE_USER);

  const handleClick = () => {
    dispatch({
      type: ACTION_TYPES.UPDATE_ANIMATION,
      data: { mode: true },
    });
    dispatch({
      type: ACTION_TYPES.UPDATE_SEARCH_QUERY,
      data: '',
    });
    navigate(ROUTES.MAIN);
  };

  const handleFinish = (value) => {
    updateUser({
      variables: {
        data: {
          fullName: value?.fullName,
        },
      },
      onCompleted() {
        getCurrentUser();
      },
    });
  };

  const ProfileDetails = () => {
    const [isProfileModal, setIsProfileModal] = useState(false);
    return (
      <div className="profile-details">
        <Flex vertical gap={2}>
          <Flex align="center" gap={8}>
            <h5 className="text-name">{user?.fullName}</h5>
            <ProfileEditIcon
              onClick={(event) => {
                event?.stopPropagation();
                setIsProfileModal(true);
              }}
            />
          </Flex>
          <p className="text-email">{user?.email}</p>
        </Flex>
        <EditProfileModal
          id={user?.id}
          name={user?.fullName}
          isOpen={isProfileModal}
          handleCancel={() => setIsProfileModal(false)}
          closeIcon={null}
          handleFinish={handleFinish}
        />
      </div>
    );
  };

  const SignOut = () => (
    <p className="text-sign-out" onClick={() => navigate(ROUTES.LOGOUT)}>
      Sign Out
    </p>
  );

  const items = [
    {
      label: <ProfileDetails />,
      key: 'PROFILE_DETAILS',
    },
    {
      label: <Divider className="m-0" />,
      key: 'SECTION_DIVIDER',
    },
    {
      label: <SignOut />,
      key: 'SIGN_OUT',
    },
  ];

  return (
    <header>
      <div className="container">
        <div
          className={`header-wrapper ${
            user?.email ? 'justify-between' : 'justify-center'
          }`}
        >
          <Logo width={172} height={30} onClick={handleClick} />
          {user?.email && (
            <div className="profile-details">
              <Dropdown
                menu={{
                  items,
                }}
                getPopupContainer={() =>
                  // eslint-disable-next-line no-undef
                  document?.getElementById('profile-details')
                }
                trigger={['click']}
                className="profile-menu-dropdown"
                placement="bottomRight"
              >
                <ProfileIcon />
              </Dropdown>
              <div id="profile-details" />
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
