import axios from 'axios';
import { navigateTo } from '../components/AppRouterHolder';
import { ROUTES } from './constants';

const api = axios?.create({
  baseURL: process.env.REACT_APP_SERVER_REST_URL,
  headers: {
    authorization: process.env.REACT_APP_AI_SERVER_REST_SECRET,
  },
});
// commenting for future use
// const toast = ({ message: content, type }) => {
//   messageContext?.destroy();
//   switch (type) {
//     case 'info':
//       messageContext?.info(content);
//       break;
//     case 'success':
//       messageContext?.success(content);
//       break;
//     case 'warning':
//       messageContext?.warning(content);
//       break;
//     case 'error':
//       messageContext?.error(content);
//       break;
//     default:
//       break;
//   }
// };

api?.interceptors?.request?.use(
  (config) => config,
  (err) => Promise?.reject(err),
);

api?.interceptors?.response?.use(
  (response) => {
    if (response?.config?.method !== 'get')
      // commenting for future use
      // toast({
      //   message: response?.data?.message || 'Operation successful!',
      //   type: response?.status === 'ERROR' ? 'error' : 'success',
      // });
      return response?.data;
  },
  (error) => {
    // commenting for future use
    // toast({
    //   message: error?.response?.data?.message || 'Something went wrong!',
    //   type: 'error',
    // });
    if (error?.response?.status === 401) {
      navigateTo(ROUTES?.LOGIN);
    }
    return Promise?.reject(error);
  },
);

export default api;
