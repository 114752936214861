import React from 'react';
import Footer from '../../app/components/footer/Footer';
import Header from '../../app/components/header/Header';

export default function AuthWrapper({ children }) {
  return (
    <div>
      <div className="page-flex">
        <Header />
        <div className="wrapper auth-wrapper">{children}</div>
        <Footer />
      </div>
    </div>
  );
}
