import React from 'react';
import AuthForm from './AuthForm';

export default function SignUp() {
  return (
    <section className="container">
      <div className="login-wrapper">
        <AuthForm allowName />
      </div>
    </section>
  );
}
